import {
  Card,
  Grid,
  Icon,
  AppBar,
  Tabs,
  Tab,
  FormControl,
} from "@mui/material";
// import { debounce, get, isEmpty } from "lodash";
import breakpoints from "assets/theme/base/breakpoints";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "context";
import PlacePicker from "components/MDPlacePicker/PlacePicker";
import Statistics from "./statistics";
import { useEffect, useState } from "react";
import ReservationsTable from "./reservationTable";
// import { getReservations } from "store/slice/reservation/reservationSlice";
import ReservationSearch from "./ReservationSearch";
import ReservationFilterByDate from "./ReservationFilterByDate";
import { getReservationStatistics } from "store/slice/reservation/reservationSlice";
import MDButton from "components/MDButton";
import * as XLSX from "xlsx";
import { amountToShow } from "global/functions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

const Reservation = () => {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const location = useLocation();
  const navigate = useNavigate();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");
  const [tabValue, setTabValue] = useState(activeTab ? activeTab : "all");
  const [page, setPage] = useState(0);
  const selectedPlace = useSelector((state) => state.places?.selectedPlace);
  const statistics = useSelector((state) => state.reservations.statistics);
  const reservationsList = useSelector(
    (state) => state.reservations?.reservationsList
  );

  let placeId = selectedPlace?._id;
  const tz = selectedPlace?.timeZoneId || "America/Los_Angeles";
  if (!placeId) {
    placeId = localStorage.getItem("placeId");
  }
  const [filterOptions, setFilterOptions] = useState({
    pageNo: page,
    status: "all",
    placeId,
    startDate: "",
    endDate: "",
    search: "",
  });

  const [isRefundIssued, setIsRefundIssued] = useState(false);
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  // const getReservationsData = useCallback(
  //   async (data) => {
  //     const payload = {
  //       tz: get(data, "tz", ""),
  //       pageNo: data.pageNo,
  //       status: data.status,
  //       placeId: data.placeId,
  //       search: get(data, "search", ""),
  //       startDate: get(data, "startDate", ""),
  //       endDate: get(data, "endDate", ""),
  //     };
  //     console.log("payload =====>", payload);
  //     dispatch(getReservations(payload));
  //   },
  //   [dispatch]
  // );

  // const debounceFn = useMemo(
  //   () => debounce(getReservationsData, 1000),
  //   [getReservationsData]
  // );

  useEffect(() => {
    dispatch(getReservationStatistics(placeId));
  }, [dispatch, tabValue, isRefundIssued, placeId]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
    queryParams.set("tab", newValue);
    const updatedQueryString = queryParams.toString();
    navigate(`?${updatedQueryString}`);
  };

  const downloadReport = (data) => {
    let filteredReservations;
    if (tabValue === "all") {
      filteredReservations = data;
    } else {
      filteredReservations = data.filter((item) => item.status === tabValue);
    }
    console.log(filteredReservations, "filtered");
    const columnMapping = {
      "Start Date": "startDate",
      "End Date": "endDate",
      "Reservation ID": "transientNumber",
      "License Plate": "licensePlate",
      "Validation Code": "validationCode",
      "Discount Percentage": "discountPercentage",
      Status: "status",
      "Total Amount": "totalAmount",
      "Base Rate": "baseRate",
      "Service Fee": "serviceFee",
      Tax: "tax",
      "Dock Revenue": "dockRevenue",
      "Payment Gateway Fee": "paymentGatewayFee",
      "Total Application Fee": "applicationFee",
      "Owner Payout": "ownerPayout",
      "Payment Method": "paymentMethodType",
      "Receipt URL": "receiptURL",
      "Extend Reminder Sent?": "isExtendReminderSend",
      "Extended Reservation": "isExtend",
      "Was Validation Applied?": "isValidationApplied",
      Purpose: "purpose",
      "created Date": "createdAt",
      "updated Date": "updatedAt",
      _id: "_id",
      shortlyId: "shortlyId",
      transactionId: "transactionId",
      paymentId: "paymentId._id",
      validationId: "validationId",
      __v: "__v",
      customerId: "customerId._id",
      rateId: "rateId._id",
      placeId: "placeId._id",
    };

    const filteredData = filteredReservations.map((item) => {
      const filteredItem = {};
      Object.keys(columnMapping).forEach((columnName) => {
        const key = columnMapping[columnName];
        let value = item[key];
        if (key === "licensePlate") {
          value = value ? value.map((plate) => plate).join(", ") : "";
        } else if (
          [
            "baseRate",
            "tax",
            "serviceFee",
            "paymentGatewayFee",
            "dockRevenue",
            "ownerPayout",
            "totalAmount",
            "applicationFee",
          ].includes(key)
        ) {
          value = value ? `$${amountToShow(value)}` : `$0`;
        } else if (key === "paymentMethodType") {
          value =
            value === "card" ? "Credit Card" : value === "ACH" ? "ACH" : value;
        } else if (key === "customerId._id") {
          value =
            item["customerId"] && item["customerId"]["_id"]
              ? item["customerId"]["_id"]
              : "";
        } else if (key === "rateId._id") {
          value =
            item["rateId"] && item["rateId"]["_id"]
              ? item["rateId"]["_id"]
              : "";
        } else if (key === "paymentId._id") {
          value =
            item["paymentId"] && item["paymentId"]["_id"]
              ? item["paymentId"]["_id"]
              : "";
        } else if (key === "placeId._id") {
          value =
            item["placeId"] && item["placeId"]["_id"]
              ? item["placeId"]["_id"]
              : "";
        }
        filteredItem[columnName] = value;
      });
      return filteredItem;
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const columnWidths = Object.keys(columnMapping).map((columnName) => {
      switch (columnName) {
        case "Reservation ID":
          return { wch: 15 };
        case "Start Date":
        case "End Date":
          return { wch: 25 };
        case "Base Rate":
        case "Tax":
        case "Service Fee":
        case "Total Amount":
          return { wch: 15 };
        case "License Plate":
        case "transactionId":
          return { wch: 30 };
        case "Receipt URL":
          return { wch: 70 };
        case "__v":
          return { wch: 10 };
        default:
          return { wch: 25 };
      }
    });
    // const columnWidths = [{ wch: 20 }, { wch: 10 }, { wch: 15 }];
    ws["!cols"] = columnWidths;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${selectedPlace.parkingCode}.xlsx`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={1}
                mt={-2}
                py={1}
                px={1}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow={sidenavColor}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Reservation
                </MDTypography>
                {/* <MDButton
                  variant="outlined"
                  size="small"
                  onClick={() => downloadReport(reservationsList)}
                >
                  Download Report
                </MDButton> */}
              </MDBox>
              {/* <MDBox p={2}>
                <Statistics data={statistics} />
              </MDBox> */}
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <PlacePicker />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <ReservationFilterByDate
                        // getReservationsData={getReservationsData}
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <ReservationSearch
                        // getReservationsData={getReservationsData}
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox p={2}>
                <Grid container spacing={1}>
                  { placeId && <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
                    <AppBar position="static">
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue}
                        onChange={handleSetTabValue}
                      >
                        <Tab
                          label="All"
                          icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}></Icon>
                          }
                          value="all"
                        />
                        <Tab
                          label="Paid"
                          icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}></Icon>
                          }
                          value="success"
                        />
                        <Tab
                          label="Failed"
                          icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}></Icon>
                          }
                          value="failed"
                        />
                        <Tab
                          label="Refunded"
                          icon={
                            <Icon fontSize="small" sx={{ mt: -0.25 }}></Icon>
                          }
                          value="refunded"
                        />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index="all">
                      <ReservationsTable
                        placeId={placeId}
                        startDate={filterOptions.startDate}
                        endDate={filterOptions.endDate}
                        searchText={filterOptions.search}
                        activeTab={tabValue}
                        tz={tz}
                        setIsRefundIssued={setIsRefundIssued}
                        isRefundIssued={isRefundIssued}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index="success">
                      <ReservationsTable
                        placeId={placeId}
                        startDate={filterOptions.startDate}
                        endDate={filterOptions.endDate}
                        searchText={filterOptions.search}
                        activeTab={tabValue}
                        tz={tz}
                        setIsRefundIssued={setIsRefundIssued}
                        isRefundIssued={isRefundIssued}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index="failed">
                      <ReservationsTable
                        placeId={placeId}
                        startDate={filterOptions.startDate}
                        endDate={filterOptions.endDate}
                        searchText={filterOptions.search}
                        activeTab={tabValue}
                        tz={tz}
                        setIsRefundIssued={setIsRefundIssued}
                        isRefundIssued={isRefundIssued}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index="refunded">
                      <ReservationsTable
                        placeId={placeId}
                        startDate={filterOptions.startDate}
                        endDate={filterOptions.endDate}
                        searchText={filterOptions.search}
                        activeTab={tabValue}
                        tz={tz}
                        setIsRefundIssued={setIsRefundIssued}
                        isRefundIssued={isRefundIssued}
                      />
                    </TabPanel>
                  </Grid>}
                </Grid>
              </MDBox>

              <MDBox></MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Reservation;
