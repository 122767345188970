import {
  LinearProgress,
  Tooltip,
  IconButton,
  Icon,
  Stack,
  Button,
} from "@mui/material";
import MDDataGrid from "components/MDDataGrid/MDDataGrid";
import MDTypography from "components/MDTypography";
import { centsToDollars } from "global/functions";
import MDBadge from "components/MDBadge";
import { useMaterialUIController } from "context";
import { debounce, get, isEmpty } from "lodash";
import MDBox from "components/MDBox";
import EmptyTableRowOverlay from "components/MDEmptyOverlay/EmptyTableRowOverlay";
import { useEffect, useMemo, useState } from "react";
import { getSubscriptionDuration } from "global/functions";
import CircularIndeterminate from "components/MDLoading";
import MDSnackbar from "components/MDSnackbar";
import { mobileNumberMasking } from "global/functions";
import { getHours } from "global/functions";
import MDButton from "components/MDButton";
import RefundDialog from "./RefundDialog";
import { getReservations } from "store/slice/reservation/reservationSlice";
import { useDispatch, useSelector } from "react-redux";
import BallparkDialog from "./ballparkDialog";

const ReservationsTable = (props) => {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const { sidenavColor } = controller;
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [issueRefundData, setIssueRefundData] = useState("");
  const [fullRefund, setFullRefund] = useState(false);
  const [ballparkView, setBallparkView] = useState(false);
  const [ballparkData, setBallparkData] = useState("");

  const {
    placeId,
    startDate,
    endDate,
    searchText,
    tz,
    activeTab,
    isRefundIssued,
    setIsRefundIssued,
  } = props;

  const reservationsList = useSelector(
    (state) => state.reservations?.reservationsList
  );
  const totalReservations = useSelector(
    (state) => state.reservations?.totalReservations
  );
  const loading = useSelector((state) => state.reservations?.loading);
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  const userData = useSelector((state) => state.users?.meInfo);
  const roleModules = userData?.roleModules || {};

  const columnsData = [
    {
      field: "orderId",
      headerName: "ID",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {` ${row.subscriptionNumber || row.transientNumber || "-"}`}
        </MDTypography>
      ),
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 300,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography display="block" variant="caption">
          {getSubscriptionDuration({
            startDate: row.startDate,
            endDate: row.endDate,
            tz: get(row, "placeId.timeZoneId", ""),
            isReservation: true,
          })}
        </MDTypography>
      ),
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography display="block" variant="caption">
          {getHours({ startDate: row.startDate, endDate: row.endDate })}
        </MDTypography>
      ),
    },
    {
      field: "rate",
      headerName: "Rate",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography display="block" variant="caption">
          {row.rateId?.displayName || "-"}
        </MDTypography>
      ),
    },

    {
      field: "licensePlate",
      headerName: "License Plates",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <div
          className="rowscroll"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {row.licensePlate.map((plate, index) => (
            <MDBadge
              key={index}
              badgeContent={<p>{plate}</p>}
              color={sidenavColor}
              variant="gradient"
              size="sm"
              sx={{ marginRight: 1 }}
            />
          ))}
        </div>
      ),
    },
    {
      field: "cellPhone",
      headerName: "Cell Phone",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {` ${mobileNumberMasking(row.customerId.mobile)}`}
        </MDTypography>
      ),
    },

    {
      field: "paymentMethod",
      headerName: "Payment Method",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.light" }}
        >
          {row.paymentMethodType === "card"
            ? "Credit Card"
            : row.paymentMethodType === "applePay"
            ? "Apple Pay"
            : row.paymentMethodType === "googlePay"
            ? "Google Pay"
            : row.paymentMethodType === "FREE PARKING"
            ? "Free Parking"
            : row.paymentMethodType === "VALIDATION CODE"
            ? "Validation Code"
            : "N/A"}
        </MDTypography>
      ),
    },
    {
      field: "isValidationApplied",
      headerName: "Validation Applied",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.dark" }}
        >
          {`${row.isValidationApplied ? row.validationCode : "N/A"}`}
        </MDTypography>
      ),
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.totalAmount !== row?.totalAmount ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.totalAmount)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.totalAmount)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.totalAmount)}`}
            </MDTypography>
          )}
        </>
      ),
    },

    {
      field: "baseRate",
      headerName: "Base Rate",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.baseRate !== row?.baseRate ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.baseRate)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.baseRate)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.baseRate)}`}
            </MDTypography>
          )}
        </>
      ),
    },

    {
      field: "tax",
      headerName: `Tax`,
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.tax !== row?.tax ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.tax)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.tax)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.tax)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "cityTax",
      headerName: `City Tax`,
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.cityTax !== row?.cityTax ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.cityTax)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.cityTax)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.cityTax)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "countyTax",
      headerName: `County Tax`,
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.countyTax !== row?.countyTax ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.countyTax)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.countyTax)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.countyTax)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "serviceFee",
      headerName: "Service Fee",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.serviceFee !== row?.serviceFee ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.serviceFee)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.serviceFee)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.serviceFee)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "ownerPayout",
      headerName: "Owner Payout",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.ownerPayout !== row?.ownerPayout ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.ownerPayout)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.ownerPayout)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.ownerPayout)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "dockRevenue",
      headerName: "DockRevenue",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.dockRevenue !== row?.dockRevenue ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.dockRevenue)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.dockRevenue)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.dockRevenue)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "paymentGatewayFee",
      headerName: "Payment Gateway Fee",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.paymentGatewayFee !==
            row?.paymentGatewayFee ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(
                  row?.withoutDiscounted?.paymentGatewayFee
                )}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.paymentGatewayFee)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.paymentGatewayFee)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "applicationFee",
      headerName: "Application Fee",
      width: 120,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.isValidationApplied &&
          row?.withoutDiscounted?.applicationFee !== row?.applicationFee ? (
            <Stack direction="row" spacing={1}>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light", textDecoration: "line-through" }}
              >
                {`$${centsToDollars(row?.withoutDiscounted?.applicationFee)}`}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                sx={{ color: "black.light" }}
              >
                {`$${centsToDollars(row?.applicationFee)}`}
              </MDTypography>
            </Stack>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              sx={{ color: "black.dark" }}
            >
              {`$ ${centsToDollars(row?.applicationFee)}`}
            </MDTypography>
          )}
        </>
      ),
    },
    {
      field: "isExtension",
      headerName: "Extend Reservation ",
      width: 120,
      editable: false,
      renderCell: ({ row }) => (
        <MDTypography
          display="block"
          variant="caption"
          sx={{ color: "black.dark" }}
        >
          {`${row.parentReservationId ? "Yes" : "No"} ${
            row.parentReservationId
              ? `( ${get(row.parentReservationId, "transientNumber", "")} )`
              : ""
          }`}
        </MDTypography>
      ),
    },
    {
      field: "isBallpark",
      headerName: "Ballpark Status",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {row.externalKey ? (
            <Tooltip
              title={`${
                !row?.placeId?.ballparc?.isEnable
                  ? "Enable Ballparc First!"
                  : ""
              }`}
              placement="top"
            >
              <div style={{ display: "inline-block", cursor: "pointer" }}>
                <Button
                  color="primary"
                  sx={{ textTransform: "none", padding: 0 }}
                  disableRipple
                  disabled={!row?.placeId?.ballparc?.isEnable}
                  onClick={() => {
                    setBallparkView(true);
                    setBallparkData({
                      placeData: row.placeId,
                      externalKey: row.externalKey,
                    });
                  }}
                >
                  Yes
                </Button>
              </div>
            </Tooltip>
          ) : (
            <MDTypography
              display="block"
              variant="caption"
              ml={3}
              sx={{ color: "black.dark" }}
            >
              No
            </MDTypography>
          )}
        </>
      ),
    },

    {
      field: "spaceNumber",
      headerName: "Space Number",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <div
          className="rowscroll"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {row?.spaceNumber && (
            <MDBadge
              key={Date.now()}
              badgeContent={<p>{row?.spaceNumber}</p>}
              color={sidenavColor}
              variant="gradient"
              size="sm"
              sx={{ marginRight: 1 }}
            />
          )}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: ({ row }) => (
        <>
          <Stack direction="row" alignItems="center">
            <Tooltip title="Download Receipt" placement="top">
              <IconButton
                color="success"
                onClick={() => {
                  if (row?.receiptURL) {
                    // window.location.href = row?.receiptURL;
                    window.open(row?.receiptURL, "_blank");
                  }
                }}
              >
                <Icon fontSize="small">download </Icon>
              </IconButton>
            </Tooltip>

            {roleModules.Reservation_refund &&
              row?.paymentId?.paymentStatus === "success" &&
              row?.paymentId?.isFullyRefunded !== true && (
                <MDButton
                  variant="gradient"
                  onClick={() => {
                    setRefundDialogOpen(true);
                    setIssueRefundData(row);
                  }}
                  fontSize="small"
                  color={sidenavColor}
                >
                  Issue Refund
                </MDButton>
              )}
          </Stack>
        </>
      ),
    },
  ];

  /* const filterReservations = (reservations) => {
    return reservations.filter(
      (reservation) => reservation?.paymentId?.isFullyRefunded !== true
    );
  }; */

  const handlePageChange = (params) => {
    getReservationsData(params.page);
    setPage(params.page);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const getReservationsData = async (pageNo) => {
    const payload = {
      tz,
      pageNo: pageNo || 0,
      status: activeTab,
      placeId,
      search: searchText,
      startDate,
      endDate,
    };
    // console.log("payload =====>", payload);
    dispatch(getReservations(payload));
  };

  const debounceFn = useMemo(
    () => debounce(getReservationsData, 1000),
    [getReservationsData]
  );

  useEffect(() => {
    debounceFn();
  }, [startDate, endDate, searchText, placeId]);

  // console.log("reservationsList ======>", reservationsList);
  return (
    <>
      <MDBox mt={2}>
        <MDDataGrid
          rows={!isEmpty(reservationsList) ? reservationsList : []}
          rowCount={totalReservations}
          columns={columnsData}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          loading={loading}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(params) => {
            handlePageChange(params);
          }}
          onPageSizeChange={(params) => handlePageSizeChange(params)}
          components={{
            NoRowsOverlay: EmptyTableRowOverlay,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 1000 },
            },
          }}
          autoHeight
        />
      </MDBox>
      <CircularIndeterminate
        type="full"
        size={20}
        text="Please wait..."
        open={loading}
      />
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
      <RefundDialog
        dialogOpen={refundDialogOpen}
        onClose={() => {
          setRefundDialogOpen(false);
          setFullRefund(false);
        }}
        data={issueRefundData}
        isRefundIssued={isRefundIssued}
        setFullRefund={setFullRefund}
        fullRefund={fullRefund}
        setIsRefundIssued={setIsRefundIssued}
      />

      <BallparkDialog
        data={ballparkData}
        dialogOpen={ballparkView}
        onClose={() => {
          setBallparkView(false);
        }}
      />
    </>
  );
};

export default ReservationsTable;
