import { combineReducers } from "@reduxjs/toolkit";
import authReducers from "./slice/auth/authSlice";
import brandReducers from "./slice/brands/brandSlice";
import roleReducer from "./slice/roles/roleSlice";
import placeReducer from "./slice/places/placeSlice";
import rateReducer from "./slice/rates/rateSlice";
import validationReducer from "./slice/validation/validationSlice";
import userReducer from "./slice/users/userSlice";
import plivoReducer from "./slice/plivo/plivoSlice";
import slackReducer from "./slice/slack/slackSlice";
import pricingTierReducer from "./slice/pricingTier/pricingTierSlice";
import qrCodeReducer from "./slice/qrCode/qrCodeSlice";
import notificationReducer from "./slice/notification/notificationSlice";
import subscriptionsReducer from "./slice/subscriptions/subscriptionSlice";
import transactionsReducer from "./slice/transactions/transactionSlice";
import reservationsReducer from "./slice/reservation/reservationSlice";
import paymentReducer from "./slice/payment/paymentSlice";
import customerReducers from "./slice/customer/customerSlice";
import paymentGatewaySlice from "./slice/paymentGateway/paymentGatewaySlice";
import messageReducer from "./slice/message/messageSlice";
import dashboardReducer from "./slice/homePage/homePageSlice";
import publicReducer from "./slice/public/publicSlice";
import reportReducer from "./slice/reports/reportSlice";
import permitReducer from "./slice/permits/permitSlice";
import otherReducer from "./slice/other/otherSlice";

const combinedReducer = combineReducers({
  auth: authReducers,
  roles: roleReducer,
  brands: brandReducers,
  places: placeReducer,
  rates: rateReducer,
  users: userReducer,
  plivos: plivoReducer,
  slacks: slackReducer,
  pricingTier: pricingTierReducer,
  validations: validationReducer,
  qrCodes: qrCodeReducer,
  notifications: notificationReducer,
  subscriptions: subscriptionsReducer,
  transactions: transactionsReducer,
  reservations: reservationsReducer,
  payment: paymentReducer,
  customer: customerReducers,
  paymentGateway: paymentGatewaySlice,
  messages: messageReducer,
  dashboard: dashboardReducer,
  public: publicReducer,
  report: reportReducer,
  permit: permitReducer,
  other: otherReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "auth/logout/fulfilled") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
