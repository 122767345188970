import { Checkbox, DialogContent, Divider, Grid } from "@mui/material";
import { Formik } from "formik";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CircularIndeterminate from "components/MDLoading";
import MDTypography from "components/MDTypography";
import { updateHelpMessage } from "store/slice/places/placeSlice";
import { getPlaces } from "store/slice/places/placeSlice";
import MDBox from "components/MDBox";

const UpdateSettings = (props) => {
  const formikRef = useRef(null);
  const { dialogOpen, onClose, data } = props;
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });
  console.log("data ===>", data);
  const handleEditMessage = async (data) => {
    try {
      setIsLoading(true);
      const res = await dispatch(updateHelpMessage(data)).unwrap();
      const success = res?.success;
      setNotification({
        ...notification,
        color: success ? "success" : "error",
        title: success ? "Success" : "Error",
        content: res?.message || "An unexpected error occurred.",
        icon: success ? "check" : "warning",
        show: true,
      });
      if (success) {
        dispatch(getPlaces());
        onClose();
      }
    } catch (err) {
      console.error("Error updating message:", err);
      setNotification({
        ...notification,
        color: "error",
        title: "Error",
        content: err?.message || "An error occurred while updating message.",
        icon: "warning",
        show: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CircularIndeterminate
        type="full"
        size={20}
        text={"Please wait..!"}
        open={isLoading}
      />
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
      <MDDialog
        dialogTitle={`Place Settings - (${data?.parkingCode})`}
        open={dialogOpen}
        dialogClose={onClose}
        closeIcon={true}
        maxWidth="xl"
      >
        <DialogContent>
          <Formik
            innerRef={formikRef}
            initialValues={{
              placeId: data?._id,
              helpText: data?.helpText,
              collectSpaceNumber: data?.collectSpaceNumber || false,
            }}
            onSubmit={(value, action) => {
              const payload = {
                placeId: data?._id,
                helpText: value?.helpText,
                collectSpaceNumber: value?.collectSpaceNumber,
              };
              handleEditMessage(payload);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <MDTypography variant="caption2">{`${data?.google?.formatted_address} - (${data?.parkingCode})`}</MDTypography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDInput
                      name="helpText"
                      value={props.values.helpText}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      label="Help Text For Receipt"
                      error={
                        props.errors.helpText && props.touched.helpText
                          ? true
                          : false
                      }
                      success={
                        props.errors.helpText && props.touched.helpText
                          ? false
                          : true
                      }
                      helperText={
                        props.errors.helpText && props.touched.helpText
                          ? props.errors.helpText
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" alignItems="center">
                      <MDTypography variant="button" fontWeight="regular">
                        &nbsp;&nbsp;Is this location a pay by space?&nbsp;
                      </MDTypography>
                      <Checkbox
                        checked={props.values.collectSpaceNumber}
                        onChange={(e) => {
                          props.handleChange(e);

                          props.setFieldValue(
                            "collectSpaceNumber",
                            e.target.checked
                          );
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} className="text-right">
                    <MDButton
                      color={sidenavColor}
                      variant="contained"
                      type="submit"
                    >
                      Update
                    </MDButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </MDDialog>
    </>
  );
};

export default UpdateSettings;
