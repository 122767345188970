import { DialogContent, Stack, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";

const DisableBallparcModal = (props) => {
  return (
    <>
      <MDDialog
        dialogTitle="Disable Ballparc"
        open={props.dialogOpen}
        dialogClose={props.onClose}
        closeIcon={true}
        maxWidth="md"
      >
        <DialogContent>
          <Typography
            variant="subtitle1"
            fontSize={16}
            fontWeight="regular"
            mb={2}
          >
            Are you sure you want to disable Ballparc?
          </Typography>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <MDButton color="danger" onClick={props.onClose}>
              No
            </MDButton>
            <MDButton color="primary" onClick={props.onDisable}>
              Yes
            </MDButton>
          </Stack>
        </DialogContent>
      </MDDialog>
    </>
  );
};

export default DisableBallparcModal;
