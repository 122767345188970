export const imageBasePath = "/assets/";

export const imageObj = {
  documentIcon: `${imageBasePath}icons/document-icon.svg`,
  uploadIcon: `${imageBasePath}icons/ic_upload.svg`,
  "After LicensePlate Submission (Two Step Validation)": `${imageBasePath}purposeImages/After-LicensePlate-Submission-(Two-Step-Validation).png`,
  "After LicensePlate Submission SMS (Two Step Validation)": `${imageBasePath}purposeImages/After-LicensePlate-Submssion-SMS-(Two-Step-Validation).png`,
  Contact: `${imageBasePath}purposeImages/Contact.png`,
  "Extension Reminder": `${imageBasePath}purposeImages/Extension-Reminder.jpg`,
  "Extension Welcome": `${imageBasePath}purposeImages/Extension-Welcome.jpg`,
  Help: `${imageBasePath}purposeImages/Help.png`,
  "Learn More": `${imageBasePath}purposeImages/Learn-More.png`,
  "License Plate Form": `${imageBasePath}purposeImages/License-Plate-Form.jpg`,
  "Monthly Rate Form": `${imageBasePath}purposeImages/Monthly-Rate-Form.jpg`,
  "On Rate Selection": `${imageBasePath}purposeImages/On-Rate-Selection.jpg`,
  "Welcome Message (Parent Rate)": `${imageBasePath}purposeImages/Parent-Rate-Welcome-Message.jpg`,
  "Payment Card (Monthly)": `${imageBasePath}purposeImages/Payment-Card-Monthly.jpg`,
  "Payment Card": `${imageBasePath}purposeImages/Payment-Card.jpg`,
  "Validation Reminder SMS": `${imageBasePath}purposeImages/Validation-Reminder-SMS-message.png`,
  "Welcome Message": `${imageBasePath}purposeImages/Welcome-Message.png`,
  "Payment Confirmation": `${imageBasePath}purposeImages/Payment-Confirmation.jpg`,
};