import {
  BottomNavigation,
  BottomNavigationAction,
  CssBaseline,
  Paper,
} from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import LogoutIcon from "@mui/icons-material/Logout";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { customerLogout } from "store/slice/customer/customerSlice";
const Footer = ({ value, setValue }) => {
  const dispatch = useDispatch();
  console.log("value", value);
  const [isLoading, setIsLoading] = useState(false);
  const [IsImpersonate, setIsImpersonate] = useState(localStorage.getItem("ImpersonateBy"))
  const superAdmin = localStorage.getItem("SuperAdmin")
  const handelLogout = () => {
    // setLoading(true);
    dispatch(customerLogout());
    window.location.href = "/parker-login";
  };
  const handelBack = async () => {
    setIsLoading(true);
    localStorage.setItem("Authorization", IsImpersonate);
    localStorage.removeItem("ImpersonateBy");
    if (superAdmin) {
      localStorage.setItem("ImpersonateBy", superAdmin);
      localStorage.removeItem("SuperAdmin");
    }
    setIsLoading(false);
    window.location.href = "/subscriptions";
  };

  return (
    <>
      <MDBox mt={5}>
        <CssBaseline />
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            {IsImpersonate ? (
              <BottomNavigationAction
                label="Back To Subscriptions"
                icon={<ArrowBack />}
                onClick={handelBack}
              />
            ) : null}
            <BottomNavigationAction
              label="Subscriptions"
              icon={<PendingActionsIcon />}
            />
            <BottomNavigationAction
              label="Payment History"
              icon={<HistoryIcon />}
            />
            <BottomNavigationAction
              label="Logout"
              icon={<LogoutIcon />}
              onClick={handelLogout}
            />
          </BottomNavigation>
        </Paper>
      </MDBox>
    </>
  );
};

export default Footer;
