import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Autocomplete, DialogContent, Grid, FormControl } from "@mui/material";

import { Formik } from "formik";

import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import CircularIndeterminate from "components/MDLoading";
import { getBallparkLocations } from "store/slice/ballpark/ballparkSlice";
import { useDispatch, useSelector } from "react-redux";
import MDSnackbar from "components/MDSnackbar";
import { useLocation } from "react-router-dom";
import { updatePlace } from "store/slice/places/placeSlice";
import { get } from "lodash";

const CreateBallparc = (props) => {
  const [controller] = useMaterialUIController();
  const dispatch = useDispatch();
  const { sidenavColor } = controller;
  const { dialogOpen, onClose, ballparcData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationData, setIsLocationData] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const location = useLocation();
  const placesData = useSelector((state) => state.places?.selectedPlace);
  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  // useEffect(() => {
  //   setIsLocationData(false);
  // }, [placesData._id, onClose]);

  const CreateBallparcValidation = Yup.object().shape({
    apiKey: Yup.string().required().label("Auth Key"),
    apiSecret: Yup.string().required().label("Auth Token"),
  });

  const handleGetLocations = (apiKey, apiSecret) => {
    if (apiKey && apiKey !== "" && apiSecret && apiSecret !== "") {
      setIsLoading(true);
      dispatch(getBallparkLocations({ apiKey, apiSecret }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setLocationData(res?.data.data);
            setIsLocationData(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setNotification({
              ...notification,
              color: "error",
              title: "Error",
              content: "Invalid Auth Key or Auth Token",
              icon: "warning",
              show: true,
            });
          }
        })
        .catch((err) => {
          console.error("Error creating brand:", err);
          setIsLoading(false);
          setNotification({
            ...notification,
            color: "error",
            title: "Error",
            content: err?.message,
            icon: "warning",
            show: true,
          });
        });
    } else {
      setIsLocationData(false);
    }
  };

  const handelBallparcSubmit = (values) => {
    console.log("Form Data", values);

    const rest = {
      ballparc: {
        ...values,
        isEnable: true
      },
    };
    const type = "UPDATE";
    setIsLoading(true);
    dispatch(updatePlace({ placeId, type, ...rest }))
      .unwrap()
      .then((res) => {
        const success = res?.success;
        setIsLoading(false);
        setNotification({
          ...notification,
          color: success ? "success" : "error",
          title: success ? "Success" : "Error",
          content: res?.message,
          icon: success ? "check" : "warning",
          show: true,
        });
        if (success) {
          // updateParentData();
          onClose();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setNotification({
          ...notification,
          color: "error",
          title: "Error",
          content: err?.message,
          icon: "warning",
          show: true,
        });
      });
  };

  useEffect(() => {
    if (isLocationData && locationData) {
      console.log("Location data found");
      return;
    }

    if (!props?.ballparcData) {
      console.log("Ballparc data not found");
      return;
    }

    const { apiKey, apiSecret } = props?.ballparcData;

    if (apiKey && apiSecret) {
      return handleGetLocations(apiKey, apiSecret);
    }

  }, [dialogOpen, props?.ballparcData?.apiSecret, props?.ballparcData?.apiKey, isLocationData, locationData]);

  // console.log("ballparcData ====>", ballparcData.apiKey);
  return (
    <>
      <CircularIndeterminate
        type="full"
        size={20}
        text="Please Wait.. "
        open={isLoading}
      />
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
      <MDDialog
        dialogTitle={props.isEditBallparc ? "Edit Ballparc" : "Create Ballparc"}
        open={dialogOpen}
        dialogClose={onClose}
        closeIcon={true}
        maxWidth="sm"
      >
        <DialogContent>
          <Formik
            initialValues={{
              apiKey: get(ballparcData, "apiKey", ""),
              apiSecret: get(ballparcData, "apiSecret", ""),
              locationId: get(ballparcData, "locationId", ""),
              isEnable: get(ballparcData, "isEnable", false),
            }}
            validationSchema={CreateBallparcValidation}
            onSubmit={(value, action) => {
              console.log("values", value);
              if (!value.locationId) {
                return handleGetLocations(value.apiKey, value.apiSecret);
              }

              handelBallparcSubmit(value);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2} className="mt-1">
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <MDInput
                        name="apiKey"
                        size="small"
                        label="Auth Key"
                        value={props.values.apiKey}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={
                          props.errors.apiKey && props.touched.apiKey
                            ? true
                            : false
                        }
                        success={
                          props.errors.apiKey && props.touched.apiKey
                            ? false
                            : true
                        }
                        helperText={
                          props.errors.apiKey && props.touched.apiKey
                            ? props.errors.apiKey
                            : null
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <MDInput
                        name="apiSecret"
                        size="small"
                        label="Auth Token"
                        value={props.values.apiSecret}
                        onChange={(e) => props.handleChange(e)}
                        onBlur={props.handleBlur}
                        error={
                          props.errors.apiSecret && props.touched.apiSecret
                            ? true
                            : false
                        }
                        success={
                          props.errors.apiSecret && props.touched.apiSecret
                            ? false
                            : true
                        }
                        helperText={
                          props.errors.apiSecret && props.touched.apiSecret
                            ? props.errors.apiSecret
                            : null
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {isLocationData && locationData.length > 0 && (
                      <FormControl fullWidth>
                        <Autocomplete
                          options={locationData}
                          getOptionLabel={(option) => option.name ?? ""}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <div>{option.name}</div>
                            </li>
                          )}
                          name="locationId"
                          autoHighlight
                          disableClearable
                          size="small"
                          value={
                            Array.isArray(locationData)
                              ? locationData.find(
                                  (option) =>
                                    option.id === props.values.locationId
                                ) || null
                              : null
                          }
                          getOptionSelected={(option, value) =>
                            option.id === value
                          }
                          onChange={(event, value) => {
                            props.setFieldValue(
                              "locationId",
                              value ? value.id : ""
                            );
                          }}
                          renderInput={(params) => (
                            <MDInput
                              label="Select Location"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              value={
                                props.values.locationId
                                  ? Array.isArray(locationData)
                                    ? locationData.find(
                                        (option) =>
                                          option.id === props.values.locationId
                                      )?.name
                                    : ""
                                  : ""
                              }
                              {...params}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Grid>

                  <Grid item xs={12} className="text-right">
                    <MDButton
                      variant="contained"
                      color={sidenavColor}
                      type="submit"
                    >
                      {isLocationData && props.values.apiKey && props.values.apiSecret ? "Save" : "Next"}
                    </MDButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </MDDialog>
    </>
  );
};

export default CreateBallparc;
