// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
// PMS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Stack } from "@mui/material";

function IntegrationInfoCard({ color, title, icon, btnText, onClick, btnText2 = "", onClick2 = () => {} }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" p={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          my={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right">
          <MDTypography variant="h4" fontWeight="medium" mb={2}>
            {title}
          </MDTypography>
          <Stack direction="row" spacing={1}>
            {btnText2 && (
              <MDButton
                variant="outlined"
                size="small"
                color={sidenavColor}
                onClick={onClick2}
              >
                {btnText2}
              </MDButton>
            )}
            <MDButton
              variant="outlined"
              size="small"
              color={sidenavColor}
              onClick={onClick}
            >
              {btnText}
            </MDButton>
          </Stack>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of IntigrationInfoCard
IntegrationInfoCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the IntigrationInfoCard
IntegrationInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default IntegrationInfoCard;
