import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateDesignReceiptAPI } from "api/designReceiptAPI";
import { GetMultipleBallparkInfoAPI } from "api/other";
import { GetBallparkInfoAPI } from "api/other";

const initialState = {
  info: [],
  infoList: [],
  loading: false,
};

export const getBallparkInfo = createAsyncThunk(
  "other/getBallparkInfo",
  async (data) => {
    try {
      const response = await GetBallparkInfoAPI(data);
      return response.data;
    } catch (err) {
      console.log("Ballpark Info error ======>", err);
      return err;
    }
  }
);
export const getMultipleBallparkInfo = createAsyncThunk(
  "other/getMultipleBallparkInfo",
  async (data) => {
    try {
      const response = await GetMultipleBallparkInfoAPI(data);
      return response.data;
    } catch (err) {
      console.log("Multiple Ballpark Info error ======>", err);
      return err;
    }
  }
);

export const slackSlice = createSlice({
  name: "others",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getBallparkInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBallparkInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.info = payload.info;
      })
      .addCase(getBallparkInfo.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMultipleBallparkInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMultipleBallparkInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.infoList = payload.infoList;
      })
      .addCase(getMultipleBallparkInfo.rejected, (state, action) => {
        state.loading = false;
      }),
});

export default slackSlice.reducer;
