import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Chip, FormControl, Icon, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Stack,
  Typography
} from "@mui/material";
import boxShadows from "assets/theme/base/boxShadows";
import MDDropDown from "components/MDDropDown";
import MDTypography from "components/MDTypography";
import DeleteDialog from "components/UIComponents/DeleteDialog";
import EditorHTMLViewBox from "components/UIComponents/RichTextEditor/EditorHTMLViewBox";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { imageObj } from "services/Images";
import { getMessage } from "store/slice/message/messageSlice";
import { deleteMessage } from "store/slice/message/messageSlice";

const MessagesList = (props) => {
  const { messages = [], selectedDay, placeId } = props;

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [purpose, setPurpose] = useState("No Filter");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [day, setDay] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageId, setMessageId] = useState("");

  const handelDelete = () => {
    dispatch(deleteMessage({ messageId }))
      .unwrap()
      .then(async (res) => {
        if (res?.success) {
          const payload = {
            placeId,
            selectedDay,
          };

          dispatch(getMessage(payload));
          setDeleteDialogOpen(false);
        }
      })
      .catch((err) => {
        console.error("Error while deleting message", err);
      });
  };

  useEffect(() => {
    setFilteredMessages(messages);
  }, [messages]);

  useEffect(() => {
    setDay(selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    if (!day) {
      return;
    }

    setFilteredMessages(messages.filter(message => {
      return message.days.some(messageDay => messageDay === day);
    }))
  }, [day, messages]);

  const emptyData = (
    <Typography variant="body2" fontSize={16}>
      No messages found!
    </Typography>
  );

  return (
    <Box pl={2} pr={2}>
      <Stack
        mb={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
      >
        <FormControl fullWidth>
          <InputLabel id="purpose-select-label">Filter by purpose</InputLabel>
          <MDDropDown
            labelId="purpose-select-label"
            id="purpose-select"
            label="Filter by purpose"
            value={purpose}
            onChange={(e) => {
              const newPurpose = e.target.value;
              if (newPurpose === "No Filter") {
                setPurpose("No Filter");
                setFilteredMessages(messages);
              } else {
                setPurpose(e.target.value);
                setFilteredMessages(
                  messages.filter(
                    (message) => message.purpose === e.target.value
                  )
                );
              }
            }}
          >
            {[
              "No Filter",
              "Welcome Message",
              "Extension Welcome",
              "Welcome Message (Validation)",
              "Welcome Message (Parent Rate)",
              "After LicensePlate Submission SMS (Two Step Validation)",
              "After LicensePlate Submission (Two Step Validation)",
              "Validation Reminder SMS",
              "On Rate Selection",
              "License Plate Form",
              "Monthly Rate Form",
              "Payment Card",
              "Payment Card (Monthly)",
              "Payment Confirmation",
              "Payment Confirmation (Monthly)",
              "Payment Confirmation SMS",
              "Extension Reminder",
              "Unavailability",
              "Help",
              "Learn More",
              "Contact",
            ].map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </MDDropDown>
        </FormControl>

        <OutlinedInput
          size="medium"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);

            const term = (e.target.value ?? "").toLowerCase();

            const searchResults = messages.filter((message) => {
              const purpose = message.purpose ?? "";
              const title = message.title ?? "";
              const subTitle = message.subTitle ?? "";
              const textMessage = message.textMessage ?? "";
              const buttonText = message.buttonText ?? "";
              const simpleMessages = message.simpleMessages ?? [];

              const includesTerm = (field) =>
                field.toLowerCase().includes(term);

              const simpleMessageIncludesTerm = simpleMessages.some(
                (simpleMessage) =>
                  (simpleMessage.message ?? "").toLowerCase().includes(term)
              );

              return (
                includesTerm(title) ||
                includesTerm(subTitle) ||
                includesTerm(textMessage) ||
                includesTerm(buttonText) ||
                includesTerm(purpose) ||
                simpleMessageIncludesTerm
              );
            });

            setFilteredMessages(searchResults);
          }}
          endAdornment={
            <InputAdornment position="end">
              {search && (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setSearch("");
                    setFilteredMessages(messages);
                  }}
                  size="medium"
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton color="secondary" size="medium">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          fullWidth={true}
        />
      </Stack>
      {!filteredMessages || filteredMessages.length < 1 ? (
        emptyData
      ) : (
        <Stack direction="column" gap={3}>
          {filteredMessages.map((message) => (
            <Stack
              p={2}
              boxShadow={boxShadows.md}
              direction="column"
              gap={2}
              borderRadius={2}
            >
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" className="fw-bold">
                    {message?.purpose}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setDeleteDialogOpen(true);
                      setMessageId(message._id);
                    }}
                    color="error"
                  >
                    <Icon fontSize="small" title="Delete">
                      delete
                    </Icon>
                  </IconButton>
                </Stack>
                <Stack display="flex" flexDirection="column">
                  {message?.title && (
                    <MDTypography
                      display="block"
                      variant="caption"
                      sx={{ color: "black.light", overflowWrap: "break-word" }}
                    >
                      {`Title - ${message?.title}`}
                    </MDTypography>
                  )}
                  {message?.subTitle && (
                    <MDTypography
                      display="block"
                      variant="caption"
                      sx={{ color: "black.light", overflowWrap: "break-word" }}
                    >
                      {`SubTitle - ${message?.subTitle}`}
                    </MDTypography>
                  )}
                  {message?.buttonText && (
                    <MDTypography
                      display="block"
                      variant="caption"
                      sx={{ color: "black.light", overflowWrap: "break-word" }}
                    >
                      {`Button Text - ${message?.buttonText}`}
                    </MDTypography>
                  )}
                  {message?.textMessage && (
                    <MDTypography
                      display="block"
                      variant="caption"
                      sx={{ color: "black.light", overflowWrap: "break-word" }}
                    >
                      {`SMS Text - ${message?.textMessage}`}
                    </MDTypography>
                  )}
                  {message?.simpleMessages?.map((item) => (
                    <EditorHTMLViewBox
                      sx={{ whiteSpace: "normal" }}
                      data={item.message}
                    />
                  ))}
                </Stack>
              </Box>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="column" gap={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    gap={1}
                  >
                    {message?.days?.length < 7 ? (
                      message?.days?.map((day, index) => (
                        <Chip
                          key={index}
                          variant="contained"
                          label={day.substr(0, 3)}
                        />
                      ))
                    ) : (
                      <Chip variant="contained" label="Everyday" />
                    )}
                  </Stack>
                  <Stack
                    fontSize={16}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography variant="body2" className="fw-bold">
                        From:
                      </Typography>
                      <Typography variant="body2">
                        {moment(message?.startTime, "hh:mm").format("hh:mm A")}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography variant="body2" className="fw-bold">
                        To:
                      </Typography>
                      <Typography variant="body2">
                        {moment(message?.endTime, "hh:mm").format("hh:mm A")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {message?.purpose && imageObj[message?.purpose] && (
                  <Box
                    component="img"
                    src={imageObj[message?.purpose]}
                    width="100%"
                    maxWidth={{ lg: "250px" }}
                    height="auto"
                  />
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
      <DeleteDialog
        dialogOpen={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        handelClick={handelDelete}
      />
    </Box>
  );
};

export default MessagesList;
