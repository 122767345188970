import {
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Collapse,
  Stack,
  IconButton,
  Divider,
  Chip,
  Autocomplete,
} from "@mui/material";

import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDDropDown from "components/MDDropDown";
import { useMaterialUIController } from "context";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import CircularIndeterminate from "components/MDLoading";
import MDSnackbar from "components/MDSnackbar";
import { chatBotMessagePurpose } from "global/constants";
import MDBox from "components/MDBox";
import { TransitionGroup } from "react-transition-group";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import RichTextEditor from "components/UIComponents/RichTextEditor/RichTextEditor";
import MDInput from "components/MDInput";
import { useDispatch, useSelector } from "react-redux";
import { createMessage } from "store/slice/message/messageSlice";
import { SMSMessagePurpose } from "global/constants";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import { getMessage } from "store/slice/message/messageSlice";
import { getCalendarDataForMessages } from "store/slice/message/messageSlice";

const CreateChatBotMessage = (props) => {
  const { dialogOpen, onClose, flowType, selectedDay } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [isLoading, setIsLoading] = useState(false);
  // const [showConstants, setShowConstants] = useState(false);
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  const placesData = useSelector((state) => state.places?.selectedPlace);
  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");

  const validationSchema = Yup.object().shape({
    purpose: Yup.string().required("Purpose is required"),
    days: Yup.array().required("Days are required").min(1, "Days are required"),
  });

  const handelCreateMessage = (data) => {
    setIsLoading(true);
    dispatch(createMessage(data))
      .unwrap()
      .then((res) => {
        console.log("Message creation response:", res);
        const success = res?.success;
        setIsLoading(false);
        setNotification({
          ...notification,
          color: success ? "success" : "error",
          title: success ? "Success" : "Error",
          content: res?.message,
          icon: success ? "check" : "warning",
          show: true,
        });
        if (success) {
          const payload = {
            selectedDay,
            placeId,
          };
          dispatch(getMessage(payload));
          dispatch(getCalendarDataForMessages(payload));
          onClose();
        }
      })
      .catch((err) => {
        console.error("Error creating Message:", err);
        setIsLoading(false);
        setNotification({
          ...notification,
          color: "error",
          title: "Error",
          content: err?.message,
          icon: "warning",
          show: true,
        });
      });
  };

  const Days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "All",
  ];

  return (
    <>
      <CircularIndeterminate
        type="full"
        size={20}
        text="Creating Message.. "
        open={isLoading}
      />
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
      <MDDialog
        dialogTitle={`Create ${flowType} Message`}
        open={dialogOpen}
        dialogClose={onClose}
        closeIcon={true}
        maxWidth="sm"
      >
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={{
              placeId: placeId,
              purpose: "Welcome Message",
              title: "",
              subTitle: "",
              buttonText: "",
              simpleMessages: [
                {
                  message: "",
                },
              ],
              flowType: flowType,
              textMessage: "",
              startTime: new Date(new Date().setHours(0, 0, 0)),
              endTime: new Date(new Date().setHours(23, 59, 59)),
              days: [],
            }}
            validationSchema={validationSchema}
            onSubmit={(value, actions) => {
              console.log("values", value);
              const payload = {
                ...value,
                startTime: moment(value?.startTime).format("HH:mm"),
                endTime: moment(value?.endTime).format("HH:mm"),
                simpleMessages: value.simpleMessages?.map((message) => message?.message.replaceAll(
                  /class="wysiwyg-mention"/g,
                  `style="pointer-events: none !important; text-decoration: none !important; color: unset !important; background-color: unset !important;"`
                )),
              };
              handelCreateMessage(payload);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2} className="mt-1">
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Purpose
                      </InputLabel>
                      <MDDropDown
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Purpose"
                        name="purpose"
                        value={props.values.purpose}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.purpose && props.touched.purpose}
                        helperText={
                          props.errors.purpose &&
                          props.touched.purpose &&
                          props.errors.purpose
                        }
                      >
                        {flowType === "BOTCOPY"
                          ? chatBotMessagePurpose.map((item) => (
                              <MenuItem key={item.title} value={item.title}>
                                {item.title}
                              </MenuItem>
                            ))
                          : SMSMessagePurpose.map((item) => (
                              <MenuItem key={item.title} value={item.title}>
                                {item.title}
                              </MenuItem>
                            ))}
                      </MDDropDown>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <DateRangePicker
                        id="timeDuration"
                        placeholder="Start Time - End Time"
                        character=" - "
                        size="lg"
                        format="hh:mm a"
                        showMeridian
                        value={
                          props.values.startTime && props.values.endTime
                            ? [
                                new Date(props.values.startTime),
                                new Date(props.values.endTime),
                              ]
                            : [
                                new Date(new Date().setHours(0, 0, 0)), // Set default start time to 12:00 AM
                                new Date(new Date().setHours(23, 59, 59)), // Set default end time to 11:59 PM
                              ]
                        }
                        ranges={[]}
                        onChange={(value) => {
                          console.log("value ====>", value);
                          if (value && value.length === 2) {
                            props.setFieldValue("startTime", value[0]);
                            props.setFieldValue("endTime", value[1]);
                          } else {
                            props.setFieldValue("startTime", null);
                            props.setFieldValue("endTime", null);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      disableClearable
                      name="days"
                      value={props.values?.days || []}
                      options={Days.filter(
                        (option) => !props.values?.days?.includes(option)
                      )}
                      onChange={(event, newValues) => {
                        props.setValues({
                          ...props?.values,
                          days:
                            newValues.indexOf("All") === -1
                              ? newValues
                              : [
                                  "Sunday",
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                ], // Update rateType to an array of selected IDs
                        });
                      }}
                      onBlur={props.handleBlur}
                      getOptionLabel={(option) => option} // Display the label (rate name)
                      renderInput={(params) => (
                        <MDInput
                          label="Days"
                          fullWidth={true}
                          helperText={
                            props.errors.days &&
                            props.touched.days &&
                            props.errors.days
                          }
                          {...params}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            variant="contained"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <MDButton
                      variant="contained"
                      color={sidenavColor}
                      size="small"
                      onClick={() => setShowConstants(!showConstants)}
                    >
                      {showConstants ? "Hide Constants" : "Show Constants"}
                    </MDButton>
                  </Grid>
                  {showConstants && (
                    <>
                      <Grid item xs={12}>
                        <MDBox display="flex" sx={{}} gap={1}>
                          <Chip label="#totalAmount" Filled />
                          <Chip label="#totalAmount" Filled />
                          <Chip label="#totalAmount" Filled />
                          <Chip label="#totalAmount" Filled />
                          <Chip label="#totalAmount" Filled />
                        </MDBox>
                      </Grid>
                    </>
                  )} */}

                  {props.values.purpose === "License Plate Form" ||
                  props.values.purpose === "Monthly Rate Form" ||
                  props.values.purpose === "Payment Card (Monthly)" ||
                  props.values.purpose === "Payment Card" ||
                  props.values.purpose === "Permit Creation Form" ? (
                    <>
                      <Grid item xs={12}>
                        <MDInput
                          name="title"
                          value={props.values.title}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          label="Title"
                          error={
                            props.errors.title && props.touched.title
                              ? true
                              : false
                          }
                          success={
                            props.errors.title && props.touched.title
                              ? false
                              : true
                          }
                          helperText={
                            props.errors.title && props.touched.title
                              ? props.errors.title
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput
                          name="subTitle"
                          value={props.values.subTitle}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          label="Sub Title"
                          error={
                            props.errors.subTitle && props.touched.subTitle
                              ? true
                              : false
                          }
                          success={
                            props.errors.subTitle && props.touched.subTitle
                              ? false
                              : true
                          }
                          helperText={
                            props.errors.subTitle && props.touched.subTitle
                              ? props.errors.subTitle
                              : null
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                  {[
                    "Extension Link Expire",
                    "Validation Link Expire",
                    "Welcome Message (Validation)",
                    "Welcome Message",
                    "On Rate Selection",
                    "Payment Confirmation",
                    "Payment Confirmation (Monthly)",
                    "License Plate Form",
                    "On Hour Selection",
                    "Unavailability",
                    "Unavailability (Extension)",
                    "Help",
                    "Learn More",
                    "Contact",
                    "Extension Welcome",
                    "Welcome Message (Parent Rate)",
                    "Second Step Validation Message",
                    "After LicensePlate Submission (Two Step Validation)",
                    "Welcome Message (Pay Now Validation Later Flow)",
                    "Payment Confirmation (Pay Now Validation Later Flow)",
                    "Permit Form Submit",
                  ].includes(props.values.purpose) ? (
                    <Grid item xs={12}>
                      <FieldArray name="simpleMessages">
                        {({ remove, push }) => (
                          <MDBox className="pt-2">
                            <TransitionGroup component="div" appear>
                              {props.values.simpleMessages?.map(
                                (message, index) => (
                                  <Collapse key={index}>
                                    <MDBox>
                                      {index !== 0 && (
                                        <MDBox mt={1}>
                                          <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                          >
                                            <Divider
                                              orientation="horizontal"
                                              flexItem
                                              light
                                              sx={{
                                                width: `calc(100% - 40px)`,
                                                margin: "auto 0 !important",
                                              }}
                                            />
                                            {props.values.simpleMessages
                                              .length > 1 && (
                                              <IconButton
                                                color="error"
                                                size="small"
                                                onClick={() => remove(index)}
                                              >
                                                <ClearOutlinedIcon />
                                              </IconButton>
                                            )}
                                          </Stack>
                                        </MDBox>
                                      )}
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <MDBox>
                                            <RichTextEditor
                                              purpose={props.values.purpose}
                                              name={`simpleMessages.${index}.message`}
                                              value={message.message}
                                              setFieldValue={
                                                props.setFieldValue
                                              }
                                              error={
                                                props.errors.simpleMessages?.[
                                                  index
                                                ]?.message
                                              }
                                              helperText={
                                                props.errors.simpleMessages?.[
                                                  index
                                                ]?.message
                                              }
                                            />
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </MDBox>
                                  </Collapse>
                                )
                              )}
                            </TransitionGroup>
                            <MDBox sx={{ mr: 2, mt: 3, mb: 1 }}>
                              <MDButton
                                variant="contained"
                                color={sidenavColor}
                                onClick={() => push({ message: "" })}
                              >
                                +
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        )}
                      </FieldArray>
                    </Grid>
                  ) : null}

                  {(props.values.purpose === "Payment Card" ||
                    props.values.purpose === "Payment Card (Monthly)") && (
                    <>
                      <Grid item xs={12}>
                        <FieldArray name="simpleMessages">
                          {({ remove, push }) => (
                            <MDBox className="pt-2">
                              <TransitionGroup component="div" appear>
                                {props.values.simpleMessages.map(
                                  (message, index) => (
                                    <Collapse key={index}>
                                      <MDBox>
                                        {index !== 0 && (
                                          <MDBox mt={1}>
                                            <Stack
                                              direction="row"
                                              justifyContent="flex-start"
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Divider
                                                orientation="horizontal"
                                                flexItem
                                                light
                                                sx={{
                                                  width: `calc(100% - 40px)`,
                                                  margin: "auto 0 !important",
                                                }}
                                              />
                                              {props.values.simpleMessages
                                                .length > 1 && (
                                                <IconButton
                                                  color="error"
                                                  size="small"
                                                  onClick={() => remove(index)}
                                                >
                                                  <ClearOutlinedIcon />
                                                </IconButton>
                                              )}
                                            </Stack>
                                          </MDBox>
                                        )}
                                        <Grid container spacing={2}>
                                          <Grid item xs={12}>
                                            <MDBox>
                                              <RichTextEditor
                                                purpose={props.values.purpose}
                                                name={`simpleMessages.${index}.message`}
                                                value={message.message}
                                                setFieldValue={
                                                  props.setFieldValue
                                                }
                                                error={
                                                  props.errors.simpleMessages?.[
                                                    index
                                                  ]?.message
                                                }
                                                helperText={
                                                  props.errors.simpleMessages?.[
                                                    index
                                                  ]?.message
                                                }
                                              />
                                            </MDBox>
                                          </Grid>
                                        </Grid>
                                      </MDBox>
                                    </Collapse>
                                  )
                                )}
                              </TransitionGroup>
                              <MDBox sx={{ mr: 2, mt: 3, mb: 1 }}>
                                <MDButton
                                  variant="contained"
                                  color={sidenavColor}
                                  onClick={() => push({ message: "" })}
                                >
                                  +
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          )}
                        </FieldArray>
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput
                          name="buttonText"
                          value={props.values.buttonText}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          label="Button Text"
                          error={
                            props.errors.buttonText && props.touched.buttonText
                              ? true
                              : false
                          }
                          success={
                            props.errors.buttonText && props.touched.buttonText
                              ? false
                              : true
                          }
                          helperText={
                            props.errors.buttonText && props.touched.buttonText
                              ? props.errors.buttonText
                              : null
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {[
                    "Extension Reminder",
                    "Payment Confirmation SMS",
                    "Validation Reminder SMS",
                    "After LicensePlate Submission SMS (Two Step Validation)",
                    "Welcome Message (SMS)",
                  ].includes(props.values.purpose) && (
                    <Grid item xs={12}>
                      <MDInput
                        name="textMessage"
                        value={props.values.textMessage}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        label="Message"
                        error={
                          props.errors.textMessage && props.touched.textMessage
                            ? true
                            : false
                        }
                        success={
                          props.errors.textMessage && props.touched.textMessage
                            ? false
                            : true
                        }
                        helperText={
                          props.errors.textMessage && props.touched.textMessage
                            ? props.errors.textMessage
                            : null
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} className="text-right">
                    <MDButton
                      variant="contained"
                      color={sidenavColor}
                      type="submit"
                    >
                      Create
                    </MDButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </MDDialog>
    </>
  );
};

export default CreateChatBotMessage;
