// @mui material components
import { useRef, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// import MDButton from "components/MDButton";
import { Formik } from "formik";

import MDButton from "components/MDButton";
import { grey } from "@mui/material/colors";
// PMS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

import { useMaterialUIController } from "context";

import { flowTypeValidationSchema } from "services/validation";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPlaceById } from "store/slice/places/placeSlice";
import { assignSetting } from "store/slice/places/placeSlice";
import { setSelectedPlace } from "store/slice/places/placeSlice";

function FlowType() {
  const formikRef = useRef(null);
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const color = grey[900];
  const dispatch = useDispatch();
  const location = useLocation();
  const placesData = useSelector((state) => state.places?.selectedPlace);
  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification({ ...notification, show: false }),
  });

  useEffect(() => {
    getPlace();
  }, [placesData?._id]);

  function getPlace() {
    if (placesData?.flowType) {
      formikRef?.current?.setFieldValue("flowType", placesData?.flowType)
      return;
    }

    dispatch(getPlaceById(placeId))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          formikRef?.current?.setFieldValue("flowType", res?.place?.flowType);
          console.log(formikRef.current, "flowtype ===>");
        }
      });
  }

  const toggleOption = (values, setFieldValue, option) => {
    let updatedOptions;

    if (values?.flowType.includes(option)) {
      updatedOptions = values?.flowType.filter((item) => item !== option);
    } else {
      if (
        values?.flowType.length === 2 ||
        (option === "SMS" && values?.flowType.includes("HYBRID")) ||
        (option === "HYBRID" && values?.flowType.includes("SMS"))
      ) {
        updatedOptions = values?.flowType.filter(
          (item) => item !== "SMS" && item !== "HYBRID"
        );
      } else {
        if (
          values?.flowType.includes("BOTCOPY") &&
          values?.flowType.includes("HYBRID")
        ) {
          updatedOptions = ["SMS"];
        } else {
          if (
            values?.flowType.includes("BOTCOPY") &&
            values?.flowType.includes("SMS")
          ) {
            updatedOptions = ["HYBRID"];
          } else {
            updatedOptions = [...values?.flowType, option];
          }
        }
      }
    }
    console.log("updatedOptions ====>", updatedOptions);
    setFieldValue("flowType", updatedOptions);
  };

  const handleFlowTypeSubmit = (value) => {
    console.log(value, "<== data");
    dispatch(assignSetting({ placeId, ...value }))
      .unwrap()
      .then((res) => {
        console.log(" response:", res);
        const success = res?.success;
        if (success) {
          dispatch(getPlaceById(placeId))
            .unwrap()
            .then((res) => {
              if (res?.success) {
                dispatch(setSelectedPlace(res?.place));
              }
            });
        }
        setNotification({
          ...notification,
          color: success ? "success" : "error",
          title: success ? "Success" : "Error",
          content: res?.message,
          icon: success ? "check" : "warning",
          show: true,
        });
      })
      .catch((err) => {
        console.error("Error creating:", err);
        setNotification({
          ...notification,
          color: "error",
          title: "Error",
          content: err?.message,
          icon: "warning",
          show: true,
        });
      });
  };

  return (
    <MDBox>
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={notification.close}
        bgWhite
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          flowType: [],
        }}
        validationSchema={flowTypeValidationSchema}
        onSubmit={(value, action) => {
          console.log("values", value);
          handleFlowTypeSubmit(value);
        }}
      >
        {({ values, setFieldValue, errors, handleSubmit }) => (
          <form
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2} className="mt-1">
              {console.log(values, "<<values")}
              <Grid item xs={12} md={4} lg={4}>
                <MDBox
                  fontSize="14px"
                  mb={1.5}
                  bgColor={
                    values?.flowType?.includes("BOTCOPY") ? sidenavColor : color
                  }
                  sx={{
                    color: "primary.contrastText",
                    pt: 4,
                    pb: 4,
                    borderRadius: "16px",
                  }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => toggleOption(values, setFieldValue, "BOTCOPY")}
                >
                  Botcopy
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <MDBox
                  fontSize="14px"
                  mb={1.5}
                  bgColor={
                    values?.flowType?.includes("SMS") ? sidenavColor : color
                  }
                  sx={{
                    color: "primary.contrastText",
                    pt: 4,
                    pb: 4,
                    borderRadius: "16px",
                  }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => toggleOption(values, setFieldValue, "SMS")}
                >
                  SMS
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <MDBox
                  fontSize="14px"
                  mb={1.5}
                  bgColor={
                    values?.flowType?.includes("HYBRID") ? sidenavColor : color
                  }
                  sx={{
                    color: "primary.contrastText",
                    pt: 4,
                    pb: 4,
                    borderRadius: "16px",
                  }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => toggleOption(values, setFieldValue, "HYBRID")}
                >
                  Hybrid
                </MDBox>
              </Grid>
              <Grid item xs={12} className="text-right">
                <MDTypography
                  display="block"
                  variant="span"
                  color="error"
                  sx={{ mb: 2 }}
                >
                  {errors?.flowType}
                </MDTypography>
                <MDButton
                  color={sidenavColor}
                  disabled={values?.flowType?.length <= 0}
                  variant="contained"
                  type="submit"
                >
                  Save
                </MDButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </MDBox>
  );
}

export default FlowType;
