import AxiosDefault from "services/AxiosDefault";

export const GetReservationsByPlaceIdAPI = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/reservations/list`,
    data: data,
  });
  return response;
};

export const GetReservationsStatisticsAPI = async (placeId) => {
  const response = await AxiosDefault({
    method: "get",
    url: `/reservations/statistics/${placeId}`,
  });
  return response;
};
