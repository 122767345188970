import React, { useState, useEffect, useRef } from "react";
import MDDialog from "components/MDDialog";
import { DialogContent } from "@mui/material";
import ReactJson from "react-json-view";
import CircularIndeterminate from "components/MDLoading";
import { get } from "lodash";
import { getBallparkInfo } from "store/slice/other/otherSlice";
import { useDispatch, useSelector } from "react-redux";
import NoDataOverlay from "components/Common/NoDataOverlay";

const BallparkDialog = (props) => {
  const { dialogOpen, onClose, data } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const ballparkInfo = useSelector((state) => state.other.info);
  const hasFetchedData = useRef(false);
  const [emptyData, setEmptyData] = useState(false);

  useEffect(() => {
    const fetchBallparkInfo = async () => {
      if (dialogOpen && !hasFetchedData.current) {
        const { placeData, externalKey } = data;
        setLoading(true);

        const payload = {
          externalKey,
          credential: {
            apiSecret: get(placeData, "ballparc.apiSecret", ""),
            apiKey: get(placeData, "ballparc.apiKey", ""),
          },
        };

        try {
          const res = await dispatch(getBallparkInfo(payload)).unwrap();
          if (res.success) {
            setLoading(false);
            setEmptyData(false);
            hasFetchedData.current = true;
          }else{
            setEmptyData(true);
            setLoading(false);
          }
       
        } catch (error) {
          console.error("Failed to fetch ballpark info:", error);
          setLoading(false);
          setEmptyData(true);
        }
      }
    };

    if (dialogOpen) {
      fetchBallparkInfo();
    }

    return () => {
      if (!dialogOpen) {
        hasFetchedData.current = false;
      }
    };
  }, [dialogOpen, data, dispatch]);

  return (
    <MDDialog
      dialogTitle="Ballpark Information"
      open={dialogOpen}
      dialogClose={onClose}
      closeIcon={true}
      maxWidth="sm"
    >
      <DialogContent>
        {loading ? (
          <CircularIndeterminate
            type="full"
            size={20}
            text="Please wait..."
            open={loading}
          />
        ) : emptyData ? (
          <NoDataOverlay />
        ) : (
          <ReactJson src={ballparkInfo} theme="monokai" collapsed={false} />
        )}
      </DialogContent>
    </MDDialog>
  );
};

export default BallparkDialog;
