import React, { useEffect } from "react";
import { Grid, Card, Switch, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const MessagePermissionCard = (props) => {
  const { modules, setModules } = props;
  useEffect(() => {
    if (modules.Message_view === undefined) {
      setModules((prevModules) => ({
        ...prevModules,
        Message_view: true,
      }));
    }
  }, [modules, setModules]);

  console.log("modules ====>", modules);

  const handleMessageViewChange = (event) => {
    const isMessageViewEnabled = event.target.checked;
    setModules({
      ...modules,
      Message_view: isMessageViewEnabled,
    });
  };

  return (
    <>
      <Card sx={{ minWidth: 275, boxShadow: 3, width: 'inherit' }}>
        <MDBox p={2}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12} container alignItems="center">
              <MDTypography
                color="dark"
                display="block"
                variant="subtitle2"
                fontWeight="bold"
                sx={{ flex: 1 }}
              >
                Message Module
              </MDTypography>
              
              <Tooltip
                title={`${
                  modules.Message_view ? "Disable" : "Enable"
                } Message module`}
                placement="top"
              >
                <Switch
                  checked={modules.Message_view}
                  onChange={handleMessageViewChange}
                />
              </Tooltip>
            </Grid>
           
          </Grid>
        </MDBox>
      </Card>
    </>
  );
};

export default MessagePermissionCard;
