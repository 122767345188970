import AxiosDefault from "services/AxiosDefault";

export const GetBallparkInfoAPI = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: "public/other/ballparkInfo",
    data,
  });
  return response;
};

export const GetMultipleBallparkInfoAPI = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: "public/other/ballparkInfoMultiple",
    data,
  });
  return response;
};