import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import IntegrationInfoCard from "./IntegrationInfoCard";
import CreateBallparc from "./components/Ballparc";
import DisableBallparcModal from "./components/Ballparc/DisableBallparcModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getPlaceById,
  updatePlace,
  setSelectedPlace,
} from "store/slice/places/placeSlice";
import { get } from "lodash";
import MDSnackbar from "components/MDSnackbar";
import CircularIndeterminate from "components/MDLoading";
import { useMaterialUIController } from "context";

const Integrations = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditBallparc, setIsEditBallparc] = useState(false);
  const [dialogOpenDisableBallparc, setDialogOpenDisableBallparc] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [placeData, setPlaceData] = useState({});
  const [notification, setNotification] = useState({
    color: "",
    icon: "",
    title: "",
    content: "",
    show: false,
    close: () => setNotification((prev) => ({ ...prev, show: false })),
  });

  const selectedPlace = useSelector((state) => state.places?.selectedPlace);
  const placeId =
    selectedPlace?._id || new URLSearchParams(location?.search).get("placeId");

  const getPlaceData = useCallback(() => {
    dispatch(getPlaceById(placeId))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setPlaceData(res?.place);
        }
      });
  }, [dispatch, placeId]);

  useEffect(() => {
    if (selectedPlace?._id) {
      setPlaceData(selectedPlace);
    } else {
      getPlaceData();
    }
  }, [selectedPlace, getPlaceData]);

  useEffect(() => {
    if (!dialogOpen || !dialogOpenDisableBallparc) {
      getPlaceData();
    }
  }, [dialogOpen, dialogOpenDisableBallparc, getPlaceData]);

  const handleIntegrationToggle = useCallback(
    (type, rest) => {
      setIsLoading(true);
      dispatch(updatePlace({ placeId, type, ...rest }))
        .unwrap()
        .then(async (res) => {
          const success = res?.success;
          setIsLoading(false);
          setNotification({
            color: success ? "success" : "error",
            title: success ? "Success" : "Error",
            content: res?.message,
            icon: success ? "check" : "warning",
            show: true,
          });
          if (success) {
            getPlaceData();
            await dispatch(setSelectedPlace(res?.updatedPlaceData));
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setNotification({
            color: "error",
            title: "Error",
            content: err?.message,
            icon: "warning",
            show: true,
          });
        });
    },
    [dispatch, placeId, getPlaceData]
  );

  const disableBallparc = useCallback(() => {
    handleIntegrationToggle("UPDATE", {
      ballparc: {
        locationId: "",
        apiKey: "",
        apiSecret: "",
        isEnable: false,
      },
    });
    setDialogOpenDisableBallparc(false);
  }, [handleIntegrationToggle]);

  const handleNotificationClose = useCallback(() => {
    setNotification((prev) => ({ ...prev, show: false }));
  }, []);

  return (
    <MDBox mb={2}>
      <Grid container alignItems="center" spacing={4} py={2}>
        {[
          {
            icon: "B",
            title: "Ballparc",
            isEnable: get(placeData, "ballparc.isEnable", false),
            onToggle: () => {
              if (get(placeData, "ballparc.isEnable", false)) {
                setDialogOpenDisableBallparc(true);
              } else {
                setIsEditBallparc(false);
                setDialogOpen(true);
              }
            },
            onEdit: () => {
              setIsEditBallparc(true);
              setDialogOpen(true);
            },
            showEdit: get(placeData, "ballparc.isEnable", false) &&
              get(placeData, "ballparc.apiKey", "") &&
              get(placeData, "ballparc.apiSecret", "") &&
              get(placeData, "ballparc.locationId", ""),
          },
          {
            icon: "T",
            title: "Tagger",
            isEnable: get(placeData, "isTaggerEnable", false),
            onToggle: () =>
              handleIntegrationToggle("UPDATE", {
                isTaggerEnable: !get(placeData, "isTaggerEnable", false),
              }),
          },
          {
            icon: "P",
            title: "PRRS",
            isEnable: get(placeData, "isPRRS", false),
            onToggle: () =>
              handleIntegrationToggle("UPDATE", {
                isPRRS: !get(placeData, "isPRRS", false),
              }),
          },
          {
            icon: "V",
            title: "Vanguard",
            isEnable: get(placeData, "isVanguardEnable", false),
            onToggle: () =>
              handleIntegrationToggle("UPDATE", {
                isVanguardEnable: !get(placeData, "isVanguardEnable", false),
              }),
          },
          { icon: "O", title: "Ocra", btnText: "Enable" },
          { icon: "P", title: "Park Plaint", btnText: "Enable" },
        ].map((integration, index) => (
          <Grid item md={4} sm={6} xs={12} key={index}>
            <IntegrationInfoCard
              color={sidenavColor}
              icon={integration.icon}
              title={integration.title}
              btnText={integration.isEnable ? "Disable" : "Enable"}
              onClick={integration.onToggle}
              btnText2={integration.showEdit ? "Edit" : ""}
              onClick2={integration.showEdit ? integration.onEdit : undefined}
            />
          </Grid>
        ))}
      </Grid>
      <CreateBallparc
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        ballparcData={placeData?.ballparc}
        isEditBallparc={isEditBallparc}
      />
      <DisableBallparcModal
        dialogOpen={dialogOpenDisableBallparc}
        onClose={() => setDialogOpenDisableBallparc(false)}
        onDisable={disableBallparc}
      />
      <CircularIndeterminate
        type="full"
        size={20}
        text="Please wait.."
        open={isLoading}
      />
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        open={notification.show}
        close={handleNotificationClose}
        bgWhite
      />
    </MDBox>
  );
};

export default Integrations;
